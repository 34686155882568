
.promotion-data{
    width: 75%;
}

.promotion-data form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.promotion-input-container{
    display: flex;
    flex-direction: column;
}

.promotion-input-container input,
.promotion-input-container select,
.promotion-data form input[type='submit']{
    width: 50%;
}

.select-selected{
    background-color: red;
}

.auth-alert{
    width: 50% !important;
}