
:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

.search-bar{
    width: 100%;
    height: 4rem;
    background-color: var(--color-pastel-light-brown);
    display: flex;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 3;
}


.toggle-navigation{
    flex: 1;
    font-size: 2rem;
    padding-left: .5rem;
    display: flex;
    align-items: center;
}

.toggle-button{
    cursor: pointer;
}

.search-block{
    flex: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-block input{
    border-radius: 30px;
    width: 50%;
    padding: .5rem 1rem;
    font-size: 1rem;
    margin: 0;
}


.profile-block{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    gap: .5rem;
    position: relative;
}

.profile-block span{
    font-size: 1rem;
}

.profile-icon{
    border-radius: 100%;
    width:2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-pastel-blue);
    color: white;
    transition: all ease-in-out 500ms;
    overflow: hidden;
}


.profile-icon:hover{
    box-shadow: 1px 1px 1px gray;
}

.profile-icon img{
    width: 100%;
    height: 100%;
}

.profile-options{
    position: absolute;
    top:4.05rem;
    right: .5rem;
    height: 18rem ;
    width: 15rem;
    border-radius: 0px 0px 5px 5px;
    background-color: var(--color-pastel-light-brown);
    display: flex;
    flex-direction: column;
    padding: .5rem;
    gap: 1rem;
}

.profile-owner{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.profile-short-info{
    display: flex;
    flex-direction: column;
    font-weight: 900;
}

.profile-short-info span:last-of-type{
    color: var(--color-pastel-blue);
    margin-top: .2rem;
}

.profile-image{
    height: 3rem;
    width: 3rem;
    background-color: var(--color-pastel-blue);
    border-radius: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    overflow: hidden;
}

.profile-image img{
    height: 100%;
    width: 100%;
}

.profile-menu{
    flex: 4;
    padding-top: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.profile-menu span{
    height: 2.5rem;
    padding: .5rem;    
    border-bottom: 1px solid var(--color-pastel-blue);
}

.profile-menu span:hover{
    border-radius: 5px;
    background-color: var(--color-pastel-vanilla);
    cursor: pointer;
}