/* ALL THESE COLORS SHOULD BE DELETED AT THE END
THEY ARE HERE JUST FOR INTELISSENSE  */

:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

.dashboard{
    width: 100%;
    height: 100%;
    display: flex;   
}

.main-dashboard{
    height: 100%;
    flex: 3;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    align-items: center;
    gap: 2rem;
}

.dash-boxes{
    height: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    width: 100%;
    max-width: 85%;
}

.box, .box-number{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box{
    /* width: 10rem; */
    height: 8rem;
    background-color: var(--color-pastel-vanilla) ;
    border-radius: 3px;   
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.box:hover{
    box-shadow: 1px 1px 3px gray;
    transform: translateY(-5px);
}


.triangle, .triangle-left{
    position: absolute;
    height: 2rem;
    width: 5rem;
    border: none;
    background-color: var(--color-pastel-blue);
    box-shadow: 1px 1px 1px gray;
    z-index: 1;
    bottom: 5px;
}

.triangle{
    right: 5px;    
}

.triangle-left{
    left: 5px;
    box-shadow: -1px 1px 1px gray;

}

.box:first-of-type .triangle-left,
.box:nth-of-type(3) .triangle{
    background-color: var(--color-pastel-vanilla);

}

.box:nth-of-type(2n){
    background-color: var(--color-pastel-blue);
}

.box-number{
    height: 65%;
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

.box-title{
    font-size: 1.2rem;
}

.box-left{
    font-size: 2.5rem;
    z-index: 2;
}

.box-content{
    text-align: right;
    z-index: 2;
}

.notifications-pannel{
    flex: 1;
    border-left: 1px solid rgba(128, 128, 128, 0.294);
    margin: 1rem 0rem;
    position: relative;
}

.circle{
    position: absolute;
    height: .75rem;
    width: .75rem;
    border: 1px solid rgba(128, 128, 128, 0.294);
    border-radius: 100%;
    background-color: white;    
    left: -.4rem;
    top: 0;
}