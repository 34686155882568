@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap'
);

:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

::selection{
    background-color: var(--color-pastel-green);
}

*{
    font-family: var(--main-font-alternative);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.home{
    display: flex;
}

.main-ground{
    width: 80%;
}

.full-screen{
    width: 100%;
}

.playground{
    width: 100%;
    height: calc(100% - 4rem);
}

