.user-signup{
    flex: .5;
}

.user-signup button,
.have-account button{
    width: auto;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    background-color: var(--color-pastel-vanilla);
    cursor: pointer;
    transition: all ease-in-out 800ms;

}

.user-signup button:hover,
.have-account button:hover{
    box-shadow: 2px 2px 5px gray;

}


.user-signup button{
    padding: .5rem;
    height: 2.2rem;
    
}

.have-account{
    flex: .5;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.have-account button{
    padding: .25rem;
    height: 2rem;

}