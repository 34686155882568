:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

.single-user-view{
    display: flex;
}

.user-details{
    width: 75%;
    display: flex;
    gap: 2rem;
}

.user-details-left{
    flex: 1;
}

.user-details-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.single-user-detail{
    display: flex;
    gap: 1rem;
}

.single-user-detail>span{
    flex: 1;
    font-size: 1.3rem;
}

.single-user-detail>span:nth-of-type(2){
    flex: .1;
}

.single-user-detail>span:last-of-type{
    font-weight: 900;
}

.user-profile-image{
    width: 21rem;
    border-radius: 10px;
}

.single-user-actions{
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}

.single-user-actions button{
    height: 2.2rem;
    width: 5rem;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all ease-in-out 500ms;
    font-size: 1rem;
    font-weight: 900;
}

.update{
    background-color: var(--color-pastel-blue);  
}

.update:hover{
    background-color: var(--color-pastel-green);
}
