:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}


.validateEMail{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.validateEmailContainer{
    width: 100vw;
    max-width: 60vw;
    height: 100vh;
    max-height: 80vh; 
    border-radius: 10px;
    background-color: var(--color-pastel-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.validateEmailContainer>span:first-of-type{
    font-size: 2.5rem;
    flex: .75;
    padding-top: 1.5rem;
    display: flex;
    align-items: center;
}

.validateEmailContainer>div:last-of-type{
    flex: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 1.1rem;
    width: 60%;

}

.code-inputs{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

#code,
#code~input{
    margin-top: 0;
    flex: 1;
}

#code~input{
    font-size: 1rem;
    flex: 1;
}

.validate-link{
    flex: 1;
    background-color: var(--color-pastel-brown);
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.not_received:hover{
    cursor: pointer;
    background-color: var(--color-pastel-light-brown);
}

.not_received{
    padding: .5rem;
    border-radius: 5px;
    width: 50%;
    margin: 0 auto;
}


.not_received>span{
    color: white;
}

.not_received:hover span{
    color: var(--color-pastel-blue);
}

.alert-code-box{
    width: 60%;
    flex: .4;
    height: 2rem;
}