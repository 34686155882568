:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

.mtcontainer{
    width: 100%;
    height: 100%;
    padding-top: 1rem;    
}

.mt-image{
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
}


tbody tr:hover{
    background-color: var(--color-pastel-vanilla);
    cursor: pointer;
}

/* NOT WORKING */
thead{
    position: sticky !important;
    top: 0 !important;
    color: blue !important;
}

.MuiPaper-root{
    border: none !important;
}

.MTableToolbar-root-5{
    padding: 0 !important;
    margin-bottom: 1rem;
}

.MTableToolbar-searchField-10{
    width: 90%;
    padding-left: 0;
    margin: 0 !important;
}

.MuiInput-root input{
    height: 2rem;
    font-size: 1.2rem;
}

.MuiInput-root input:focus,
.MuiInput-root input:hover{
    border:none;
    outline: none;
}

.MTableToolbar-spacer-7{
    display: none;
}

.MTableToolbar-actions-8{
    color: var(--color-pastel-blue) !important;
    margin-left: 2rem;
}

tfoot{
    border-bottom: none !important;
}

.MuiTablePagination-root:last-child{
    border-bottom: none !important;
}

.student-form-title{
    display: flex;
    justify-content: space-between;
}

.student-form-title .add-button{
    color: white;
    background-color: var(--color-pastel-blue);
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
}

.edit-row{
    color: var(--color-pastel-blue);
    font-size: 1.2rem;
}

.delete-row{
    color: rgba(255, 0, 0, 0.566);
    font-size: 1.2rem;
}

.MuiPaper-elevation2{
    box-shadow: none !important;
}

.delete-modal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modal-title{
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
}

.modal-controls{
    flex: 1;
    display: flex;
    justify-content: right;
    gap: 2rem;
}

.modal-controls button{
    height: 2.5rem;
    width: 4.5rem;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all ease-in-out 500ms;
    font-weight: 700;
}

.cancel{
    background-color: var(--color-pastel-blue);
}

.cancel:hover{
    background-color: gray;
    color: white;
}

.delete{
    background-color: rgba(255, 0, 0, 0.5);
}

.delete:hover{
    background-color: red;
    color: white;
}
