:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

::-webkit-scrollbar{
    width: 5px;
    border: none;
}

::-webkit-scrollbar-track{
    box-shadow: inset 0 0 2px gray;
}

::-webkit-scrollbar-thumb{
    background-color: var(--color-pastel-blue);
    border-radius: 10px;
}


.stats{
    width: 100%;
    max-width: 85%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding-bottom: 5rem;
}



.stat-title{
    font-size: 1.3rem;
    color:var(--color-pastel-blue);    
}

hr{
    margin-top: .5rem;
    border: .5px solid rgba(128, 128, 128, 0.128);
    background-color: rgba(128, 128, 128, 0.128);
}

.stat-chart{
    height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.stat-chart-left{
    flex: 2;
    height: 100%;
    display: flex;
    align-items: center;    
}

.bar-chart{
    width: 100%;
    height: 35rem;
    z-index: 1;
}

.pie-chart{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
}

.stat-chart-right{
    flex: 1.5;
    height: 100%;
    margin-top: .75rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.stat-chart-right-up,
.stat-chart-right-down{
    width: 100%;
    background-color: var(--color-pastel-vanilla);
    border-radius: 5px;
}

.stat-chart-right-down{
    flex: .75;
}

.stat-chart-right-up{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
}

.stat-chart-right-up>span{
    background-color: var(--color-pastel-brown);
    padding: .5rem;
    position: sticky;
    top: 0;
}

.stat-chart-right-up .last-added, 
.last-added-info{
    display: flex;
    flex-direction: column;
}

.last-added a{
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    border-radius: 5px;
    height: 3.5rem;
    padding-left: .5rem;
    
}

.last-added a, .last-added hr{
    margin: .2rem .5rem;
}

.last-added a:hover{
    background-color: var(--color-pastel-green);    
}

.last-added-image{
    height: 2.5rem;
    width: 2.5rem;
    background-color: rgba(128, 128, 128, 0.49);
    border-radius: 100%;
}

.last-added-info span:last-of-type{
    font-size: .75rem;
    color: gray;
}