@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap'
);

:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

::selection{
    background-color: var(--color-pastel-green);
}

*{
    font-family: var(--main-font-alternative);
}


.login{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer{
    width: 100vw;
    max-width: 60vw;
    height: 100vh;
    max-height: 90vh;    
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

/* LEFT SECTION LOGIN */
.left-login{
    height: 100%;
    flex: 2.5;
    background-color:var(--color-pastel-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0rem .5rem;
}

.login-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pastel-green);
    border-radius: 0px 0px 10px 10px;
    flex: 1;
    width: 45%;
    padding: 0rem .5rem;
    text-align: center;
}

.logo{
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

.login-signup{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-question{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
} 

.signup-question~button{
    width: 8rem;
    height: 3rem;
    border-radius: 5px;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    background-color: var(--color-pastel-green);
    cursor: pointer;
    transition: all ease-in-out 800ms;
}

.signup-question~button:hover{
    box-shadow: 2px 2px 5px gray;
}


/* RIGHT SECTION LOGIN */
.right-login{
    height: 100%;
    flex: 2;
    background-color: var(--color-pastel-vanilla) ;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all ease-in-out 800ms;

}

.loginHeader, .signupHeader{
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-pastel-brown);
    margin: 0px 10px ;
    gap: 20px;
}

.loginHeader>span, .signupHeader>span{
    display: none;
}

.signup-page-link,
.login-page-link{
    cursor: pointer;
    background-color: var(--color-pastel-green);
    padding: .30rem .4rem;
    border-radius: 5px;
    transition: all ease-in-out 800ms;
}

.signup-page-link:hover,
.login-page-link:hover{
    background-color: var(--color-pastel-brown);
}


.loginForm, .signupForm{
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signupForm{
    padding-bottom: 1rem;
    overflow-y: scroll;

}



.input-block{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 1rem 0rem;
}

.signupForm .input-block{
    margin:.5rem 0rem;
}

.signupForm .input-block:first-of-type{
    margin-top:1rem
}

.signupForm input{
    height: 2.3rem;
}

input, select{
    height: 2.5rem;
    font-size: 1rem;
    margin-top: .4rem;
    border: 1px solid var(--color-pastel-brown);
    outline: none;
    padding: .5rem;
    font-weight: 900;
    border-radius: 4px;
}

select{
    width: 100% !important;
}


input[type='submit']{
    width: 75%;
    margin-top: 1rem;
    padding: 0;
    background-color: var(--color-pastel-brown);
    border: none;
    cursor: pointer;
    transition: all ease-in-out 800ms;
}

input[type='submit'].signupButton{
    margin-top: 1rem;
    height: 3rem !important;
    padding: .75rem;
    
}

input[type='submit']:hover{
    background-color: var(--color-pastel-green);
}


.showPassword-block{
    display: flex;
    align-items: center;
    width: 75%;
    height: 2rem;
    gap: .5rem;
    width: 100%;
    margin-top: .5rem;
}

.showPassword-block input{
    width: 1.2rem;
}

.showPassword-block label{
    margin-top: .35rem;
}

.alert{
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.alert .alert-message{
    width: 75%;
    
}

@media only screen and  (max-width:1024px){

    .loginContainer{
        max-width: 70vw !important;
    }

    .login-logo{
        width: 65%;
    }

}

@media only screen and  (max-width:845px){

    .loginContainer{
        max-width: 80vw !important;
    }

    .login-logo{
        width: 75%;
    }
}

@media only screen and  (max-width:675px){

    .login-logo{
        background: none;
    }
}

@media only screen and  (max-width:575px){

    .left-login{
       display: none;
    }

    .loginContainer{
        max-width: 60vw !important;
    }

    .loginHeader>span, .signupHeader>span{
        display: block;
    }
}

@media only screen and  (max-width:456px){
    .loginContainer{
        max-width: 80vw !important;
    }
}