@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap'
);

:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

::selection{
    background-color: var(--color-pastel-green);
}

*{
    font-family: var(--main-font-alternative);
}


.navbar{
    height: 100vh;
    width: 20%;
    background-color: var(--color-pastel-blue);
    padding: .5rem;
    position: sticky;
    left: 0;
    top: 0;
}

.nav-logo{
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    flex-direction: column;
    gap: 15px;
    border-bottom: 1px solid gray;
}

.nav-logo>span{
    font-size: .9rem;
}

.nav-menu{
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem .5rem 1rem .5rem;
    overflow-y: auto;
}


.menu-item{
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-items: center;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.menu-item:hover .submenu-toggle{
    background-color: var(--color-pastel-green);
    border-radius: 5px 5px 0px 0px;
}

.menu-name{
    flex: 5;
}

.fa-icon{
    flex: 1;
}

.submenu-toggle{
    display: flex;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid var(--color-pastel-blue);
}

.submenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 22%;
    padding-bottom: 1rem;
    width: 50%;
}

.submenu span{
    padding: .5rem;
    border-radius: 5px;
}
.submenu span:hover{
    background-color: var(--color-pastel-green);
}