
:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

.playground{
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
}

.student-form-title, .student-form{
    width: 75%;
}

.student-form-title span{
    font-size: 2rem;
}

.student-form{
    display: flex;
    flex-direction: column;
    
}

.student-detail{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;
}

.student-detail input, select{
    width: 60%;
    font-size: 1rem;
}

select{
    background-color: white;
}

.add-student{
    width: 60% !important;
}

input:focus,
input:hover,
select:hover,
select:focus{
    border: 2px solid var(--color-pastel-green);
}

.required-star{
    color: red;
    font-weight: 600;
}

.message-container{
    height: 2rem;
    background-color: red;
    width: 100%;
}

.alert-message{
    margin-bottom: 2rem;
    width: 60%;
}

