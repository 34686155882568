.playground{
    gap: 2rem !important;
}

.alert-container{
    width: 75%;
}

.alert-message{
    margin-bottom: 0 !important;
}
