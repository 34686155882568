
:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}

.modifyProfile{
    display: flex;
}

.profile-data{
    height: 45rem;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.profile-data-header{
    height: 37%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 0rem 1rem 1rem;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--color-pastel-vanilla);
}

.profile-data-header .profile-image{
    height: 10rem;
    width: 10rem;
    background-color: var(--color-pastel-blue);
    font-size: 6rem;
}

.profile-data-name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
}

.profile-data-name>span:first-of-type{
    font-size: 1.5rem;
}

.profile-data-name>span:last-of-type{
    color:gray;
    font-size: 1.2rem;
}

.profile-form{
    height: 63%;
    width: 100%;
}

.profile-form form{
    width: 100%;
    height: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.profile-form-left{
   width: 50%;
   height: 100%;
}

.profile-form-right{
    width: 50%;
    height: 100%;
}

.modifyButton input,
.modifyButton button{
    width: 6rem;
    font-size: 1.2rem;
    height: 2.5rem;
    background-color: var(--color-pastel-brown);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all ease-in-out 500ms;
    font-weight: 900;
    margin-top: 1rem;
}

.modifyButton button:hover{
    background-color: var(--color-pastel-green);
}

#account_type{
    width: 50% !important;
}

input:disabled,
select:disabled{
    opacity: 1 !important;
    color: black;
    background-color: var(--color-pastel-vanilla);
}


.alertBox{
    width: 80%;
    height: auto;
}

.alertBox .alert-message{
    width: 100%;
}