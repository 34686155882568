
:root{
    /* COLORS */
    --color-pastel-blue:#9EACC1;
    --color-pastel-brown: #DABAC3;
    --color-pastel-light-brown:#EFDDE6;
    --color-pastel-vanilla:#FBF0F7;
    --color-pastel-green:#C4D5D6;

    /* FONTS */
    --main-font:'Poppins';
    --main-font-alternative:'Lato';

}


.error-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: var(--color-pastel-blue);
}

.error-page>span:first-of-type{
    font-size: 7rem;
    font-weight: 900;
}

.error-page>span:nth-of-type(2){
    font-size: 2rem;
    margin-bottom: 2rem;
}